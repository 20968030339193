import { toast,Zoom } from "react-toastify";
import { RotatingLines } from 'react-loader-spinner'

import moment from 'moment';

// export const apibaseURl = 'http://localhost:5000/api/'
// export const fileUpload = 'http://localhost:5000/uploads/'

export const apibaseURl = 'https://winwin-app.io/server/api/'
export const fileUpload = 'https://winwin-app.io/server/uploads/'

// "homepage": "https://p.softechplanet.com/winwinapp",
// "homepage": "https://winwin-app.io",

// PUBLIC_URL=/winwinapp

// export const apibaseURl = process.env.REACT_APP_API_BASE_URL;
// export const fileUpload = process.env.REACT_APP_FILE_UPLOAD_URL;

export const showErrorToast = (text, autoClose = '5000', positions='top-right') => {
    toast.error(text,
        {
          autoClose: autoClose,
          position: positions,
          transition: Zoom,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: true,
        });
};

export const showSuccessToast = (text, autoClose = '3000', positions='top-right') => {
    toast.success(text,
        {
          autoClose: autoClose,
          position: positions,
          transition: Zoom,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: true,
        })
};
 
export const formatDateToCustomString = (date) => {
  return moment(date).format('MMM Do YYYY');
};

// export function formatDateToCustomString(isoString) {
//   const date = new Date(isoString);
//   const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//   const monthIndex = date.getMonth();
//   const day = date.getDate();
//   const year = date.getFullYear();
//   let hours = date.getHours();
//   let minutes = date.getMinutes();
//   const ampm = hours >= 12 ? 'pm' : 'am';
//   hours = hours % 12;
//   hours = hours ? hours : 12; // Handle midnight case (0 hours)
//   minutes = minutes < 10 ? '0' + minutes : minutes;
//   const formattedTime = hours + ':' + minutes + ' ' + ampm;
//   // const formattedDate = `${months[monthIndex]} ${day}th ${year}, ${formattedTime}`;
//   const formattedDate = `${months[monthIndex]} ${day}th ${year} , ${formattedTime}`;
//   return formattedDate;
// }




export const convertTo12HourFormat = (time) => {
  const [hours, minutes] = time.split(':');
  let period = 'AM';
  
  let hoursIn12Hour = parseInt(hours, 10);
  
  if (hoursIn12Hour >= 12) {
    period = 'PM';
    if (hoursIn12Hour > 12) {
      hoursIn12Hour -= 12;
    }
  } else if (hoursIn12Hour === 0) {
    hoursIn12Hour = 12;
  }

  return `${hoursIn12Hour}:${minutes} ${period}`;
};

export const formatDate = (mongoDate) => {
  const date = new Date(mongoDate);
  if (!isNaN(date)) {
      return date.toISOString().split('T')[0];
  } else {
      return '';
  }
};

export const tokengenerate = ()=>{
  let tokendata = JSON.parse(localStorage.getItem('winwinUser'));
   if(tokendata && tokendata?.token !== undefined && tokendata?.token !=="" && tokendata?.token !== null){
     return tokendata?.token;
   }
  return false;
}

export const loginUserDetailkey = (key="")=>{
  let loginUserdata = JSON.parse(localStorage.getItem('winwinUser'));
  if(loginUserdata){
      return loginUserdata.user[key];
  }
  return false;
}

export const userInfo = ()=>{
  let userInfo = JSON.parse(localStorage.getItem('winwinUser'));
   if(userInfo && userInfo?.user !== undefined && userInfo?.user !=="" && userInfo?.user !== null){
       return userInfo?.user;
   }
  return false;
}

export const userRole = ()=>{
  let userRole = JSON.parse(localStorage.getItem('winwinUser'));
   if(userRole && userRole?.user?.role !== undefined && userRole?.user?.role !=="" && userRole?.user?.role !== null){
       return userRole?.user?.role;
   }
  return false;
}


export const getvalidationErr = (errors) => {
  const errorsConvertInArry = Object.values(errors);
  let collectAllValidationErr = [];
  errorsConvertInArry.forEach((error, index) => {
    collectAllValidationErr.push(<div key={index}>{error.msg}</div>);
  });
  const storeAllValidtionErr = (
    <>
      {collectAllValidationErr}
    </>
  );
  return storeAllValidtionErr;
  
}

export const loaderFunction =()=>{
  return(
    <RotatingLines
    visible={true}
    height="40"
    width="40"
    strokeColor="#000"
    strokeWidth="5"
    animationDuration="0.75"
    ariaLabel="rotating-lines-loading"
    wrapperStyle={{}}
    wrapperClass=""
    />
  )
}

export const getTimeAgo = (notificationDate) => {
  const currentDate = new Date();
  const notificationTime = new Date(notificationDate);
  if (isNaN(notificationTime)) {
    return "Invalid date";
  }
  const timeDifference = Math.floor((currentDate - notificationTime) / 1000); // Difference in seconds
  if (timeDifference < 1) {
    // If the difference is less than 1 second, return 'Just now'
    return "Just now";
} else if (timeDifference < 60) {
      // If less than 60 seconds
      return `${timeDifference} sec${timeDifference === 1 ? '' : 's'} ago`;
  } else if (timeDifference < 3600) {
      // If less than 60 minutes (3600 seconds)
      const minutes = Math.floor(timeDifference / 60);
      return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
  } else if (timeDifference < 86400) {
      // If less than 24 hours (86400 seconds)
      const hours = Math.floor(timeDifference / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
      // If more than 24 hours
      const days = Math.floor(timeDifference / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
  }
}

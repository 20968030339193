import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import profileimg from '../../assets/img/dummyUser.png'
import Storelogo from '../../assets/img/download.jpg'
import storePhoto from '../../assets/img/back_img.jpg';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import amount from '../../assets/img/download.svg';
import commission from '../../assets/img/commission.png';
import dummyUserImg from '../../assets/img/download.jpg';
import { CiLocationOn } from "react-icons/ci";
import { FaWallet } from "react-icons/fa6";
import { PiPercentFill } from "react-icons/pi";
import axios from 'axios';
import { apibaseURl, showErrorToast, tokengenerate, fileUpload, loaderFunction, showSuccessToast, convertTo12HourFormat } from '../../../Function'
import { AccountContext } from '../../adminContext/AccountProvider';
import { GlobalExportdata } from '../../../App';

const StoreSingleView = () => {
  const { setIstoken } = useContext(AccountContext);
  const { currency } = useContext(GlobalExportdata);
  const [singleStoreData, setSingleStoreData] = useState({})
  const [singleStoreRevenue, setSingleStoreRevenue] = useState("")
  const [singleStoreCommission, setSingleStoreCommission] = useState("")
  const [storeCommissionUpdate, setStoreCommissionUpdate] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const [walletAmount, setWalletAmount] = useState("")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { id } = useParams();

  const handleGetCummission = (item) => {
    handleShow();
    setStoreCommissionUpdate(item)
  };



  // fetch Single Store Api Call here ==================>
  const fetchSingleStore = async () => {
    try {
      const response = await axios.get(`${apibaseURl}store/${id}`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });

      if (response.status == 200 && response.data.success === true) {
        setSingleStoreData(response?.data?.data?.store)
        setSingleStoreRevenue(response?.data?.data?.Revenue)
        setSingleStoreCommission(response?.data?.data?.commission)
        setWalletAmount(response?.data?.data?.wallet_Amount?.total_wallet_amount)
        setIsLoading(false)
      } else {

      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchSingleStore();
  }, [])

  // store Commission Update here ===========================>
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "Commission" && !/^\d*\.?\d*$/.test(value)) {
      return;
    }
    setStoreCommissionUpdate(value)
  }
  const updateStoreCommission = async () => {
    try {
      const userData = {
        store_commission_percentage: storeCommissionUpdate,
        id: id
      };
      const response = await axios.put(`${apibaseURl}storecommission-update/${id}`, userData, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });

      if (response.status == 200 && response.data.success === true) {
        showSuccessToast(response?.data?.message)
        fetchSingleStore();
        handleClose()
      } else {

      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    } finally {
      setIsLoading(false);
    }
  }

  // 


  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Store Manager</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/store">Store</Link>
                </li>

                <li className="breadcrumb-item active">Store Details</li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/orders">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Total Orders</h6>
                      <h3>0</h3>
                    </div>
                    <div className="db-icon">
                      <img src={order} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/orders">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Order Pending</h6>
                      <h3>0</h3>
                    </div>
                    <div className="db-icon">
                      <img src={order} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/orders">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Order Process</h6>
                      <h3>0</h3>
                    </div>
                    <div className="db-icon">
                      <img src={order} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/orders">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Order Completed</h6>
                      <h3>0</h3>
                    </div>
                    <div className="db-icon">
                      <img src={order} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div> */}
        {isLoading ?
          <div className='div_loader_set'>
            {loaderFunction()}
          </div>
          :
          (
            <>
              <div className='row'>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                  <div className="card bg-comman w-100">

                    <div className="card-body">
                      <div className="db-widgets d-flex justify-content-between align-items-center">
                        <div className="db-info">
                          <h6>Today’s Revenue</h6>
                          <h3>{currency} {singleStoreRevenue.today_revenue != null && singleStoreRevenue.today_revenue != undefined && singleStoreRevenue.today_revenue != "" ? singleStoreRevenue.today_revenue : "0.00"}</h3>
                        </div>
                        <div className="db-icon">
                          <img src={amount} alt="Dashboard" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                  <div className="card bg-comman w-100">

                    <div className="card-body">
                      <div className="db-widgets d-flex justify-content-between align-items-center">
                        <div className="db-info">
                          <h6>Yesterday’s Revenue</h6>
                          <h3>{currency} {singleStoreRevenue.yesterday_revenue != null && singleStoreRevenue.yesterday_revenue != undefined && singleStoreRevenue.yesterday_revenue != "" ? singleStoreRevenue.yesterday_revenue : "0.00"}</h3>
                        </div>
                        <div className="db-icon">
                          <img src={amount} alt="Dashboard" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                  <div className="card bg-comman w-100">

                    <div className="card-body">
                      <div className="db-widgets d-flex justify-content-between align-items-center">
                        <div className="db-info">
                          <h6>This Month’s Revenue</h6>
                          <h3>{currency} {singleStoreRevenue.this_month_revenue != null && singleStoreRevenue.this_month_revenue != undefined && singleStoreRevenue.this_month_revenue != "" ? singleStoreRevenue.this_month_revenue : "0.00"}</h3>
                        </div><br />
                        <div className="db-icon">
                          <img src={amount} alt="Dashboard" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                  <div className="card bg-comman w-100">

                    <div className="card-body">
                      <div className="db-widgets d-flex justify-content-between align-items-center">
                        <div className="db-info">
                          <h6>This Year’s Revenue</h6>
                          <h3>{currency} {singleStoreRevenue.this_year_revenue != null && singleStoreRevenue.this_year_revenue != undefined && singleStoreRevenue.this_year_revenue != "" ? singleStoreRevenue.this_year_revenue : "0.00"}</h3>
                        </div>
                        <div className="db-icon">
                          <img src={amount} alt="Dashboard" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                {/* Commission Section */}
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                  <div className="card bg-comman w-100">

                    <div className="card-body">
                      <div className="db-widgets d-flex justify-content-between align-items-center">
                        <div className="db-info">
                          <h6>Today’s Commission</h6>
                          <h3>{currency} {singleStoreCommission.today_commission != null && singleStoreCommission.today_commission != undefined && singleStoreCommission.today_commission != "" ? singleStoreCommission.today_commission : "0.00"}</h3>
                        </div>
                        <div className="db-icon">
                          <img src={commission} alt="Dashboard" width="50px" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                  <div className="card bg-comman w-100">

                    <div className="card-body">
                      <div className="db-widgets d-flex justify-content-between align-items-center">
                        <div className="db-info">
                          <h6>Yesterday’s Commission</h6>
                          <h3>{currency} {singleStoreCommission.yesterday_commission != null && singleStoreCommission.yesterday_commission != undefined && singleStoreCommission.yesterday_commission != "" ? singleStoreCommission.yesterday_commission : "0.00"}</h3>
                        </div>
                        <div className="db-icon">
                          <img src={commission} alt="Dashboard" width="50px" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                  <div className="card bg-comman w-100">

                    <div className="card-body">
                      <div className="db-widgets d-flex justify-content-between align-items-center">
                        <div className="db-info">
                          <h6>This Month’s Commission</h6>
                          <h3>{currency} {singleStoreCommission.this_month_commission != null && singleStoreCommission.this_month_commission != undefined && singleStoreCommission.this_month_commission != "" ? singleStoreCommission.this_month_commission : "0.00"}</h3>
                        </div>
                        <div className="db-icon">
                          <img src={commission} alt="Dashboard" width="50px" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                  <div className="card bg-comman w-100">

                    <div className="card-body">
                      <div className="db-widgets d-flex justify-content-between align-items-center">
                        <div className="db-info">
                          <h6>This Year’s Commission</h6>
                          <h3>{currency} {singleStoreCommission.this_year_commission != null && singleStoreCommission.this_year_commission != undefined && singleStoreCommission.this_year_commission != "" ? singleStoreCommission.this_year_commission : "0.00"}</h3>
                        </div>
                        <div className="db-icon">
                          <img src={commission} alt="Dashboard" width="50px" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className='row'>
                    <div className='col-md-12 mb-3'>
                      <div className="profile-header-store_bar">
                        <div className="">
                          <div
                            className="col-auto profile-image store_view_data"
                            style={{
                              backgroundImage: `url(${singleStoreData?.store_photos ? fileUpload + singleStoreData?.store_photos : storePhoto})`,
                              backgroundSize: 'cover', // Optional: to cover the entire div
                              backgroundPosition: 'center', // Optional: to center the image
                            }}
                          >
                            <img
                              className="store_back_logo"
                              alt="User"
                              src={singleStoreData?.store_logo ? fileUpload + singleStoreData?.store_logo : Storelogo}
                            />
                          </div>

                          <div className=" profile-user-info profile_user_info_check">
                            <span className="store_info">{singleStoreData?.store_name}</span>
                            <span className="store_info_location">
                              <CiLocationOn className='store_location_icon' />
                              {singleStoreData?.store_address}
                            </span>
                          </div>

                          <div className='wallet_history_check'>
                            <p className='wallet_amount_test'><FaWallet /> Wallet : {currency} {walletAmount ?? "0.00"}</p>
                            <div className='text-center'>
                              <Link to={`/admin/store/store-wallet/${id}`} className="btn btn-sm btn-danger store_add_wallet_add">
                                <FaWallet />&nbsp;
                                Payout
                              </Link>
                            </div>
                          </div>


                          <div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className="profile-header-store">
                        <div className="row align-items-center justify-content-center">
                          <div className="col-auto profile-image">
                            {singleStoreData?.image != null && singleStoreData?.image != undefined ?
                              <img
                                className="rounded-circle"
                                alt="User"
                                src={fileUpload + singleStoreData?.image}
                              />
                              :
                              <img
                                className="rounded-circle"
                                alt="User"
                                src={profileimg}
                              />
                            }

                          </div>

                          <div className=" profile-user-info profile_user_info_check">
                            <span className="store_info">Restaurant Owner</span>
                            <span className="store_info_location">Restorent Owner</span>
                          </div>
                          <div className="profile-menu-store">
                            <div className="row">
                              <p className="col-sm-3 text-muted">Name</p>
                              <p className="col-sm-9">{singleStoreData?.name}</p>
                            </div>
                            <div className="row">
                              <p className="col-sm-3 text-muted">Email</p>
                              <p className="col-sm-9 line_break_space">
                                {singleStoreData?.email}
                              </p>
                            </div>
                            <div className="row">
                              <p className="col-sm-3 text-muted">Mobile</p>
                              <p className="col-sm-9">{singleStoreData?.phone}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-8'>
                  <div className="profile-menu-store-check mb-5">
                    <>
                      <div className="row">
                        <p className="col-sm-6 text-muted">Store Name</p>
                        <p className="col-sm-6">{singleStoreData?.store_name}</p>
                      </div>

                      <div className="row">
                        <p className="col-sm-6 text-muted">Store Email ID</p>
                        <p className="col-sm-6">{singleStoreData?.store_email}</p>
                      </div>

                      <div className="row">
                        <p className="col-sm-6 text-muted">Store Support Number</p>
                        <p className="col-sm-6">{singleStoreData?.store_support_number}</p>
                      </div>

                      <div className="row">
                        <p className="col-sm-6 text-muted">Store Contact Person Name </p>
                        <p className="col-sm-6">{singleStoreData?.contact_person_name}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-6 text-muted">Store Contact Person Number </p>
                        <p className="col-sm-6">{singleStoreData?.contact_person_phone}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-6 text-muted">Store Commission (%) </p>
                        <p className="col-sm-6">
                          {
                            singleStoreData?.store_commission_percentage != null &&
                              singleStoreData?.store_commission_percentage != undefined &&
                              singleStoreData?.store_commission_percentage != "" ?
                              singleStoreData?.store_commission_percentage
                              : "NA"
                          }
                        </p>
                      </div>
                      <div className="row">
                        <p className="col-sm-6 text-muted">Store Open Time </p>
                        <p className="col-sm-6">{convertTo12HourFormat(singleStoreData.open_time)}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-6 text-muted">Store Close Time </p>
                        <p className="col-sm-6">{convertTo12HourFormat(singleStoreData.close_time)}  </p>
                      </div>
                      <div className="row">
                        <p className="col-sm-6 text-muted">Store Address</p>
                        <p className="col-sm-6">{singleStoreData?.store_address}</p>
                      </div>

                      {singleStoreData?.rejected_reason != null && singleStoreData?.rejected_reason != "" &&
                        singleStoreData?.rejected_reason != undefined && (
                          <div className="row">
                            <p className="col-sm-6 text-muted">Store Reject Reason</p>
                            <p className="col-sm-6">{singleStoreData?.rejected_reason}.</p>
                          </div>
                        )}


                      <div className="row mt-5">
                        <div className='col-md-12 d-flex'>
                          <Link to={`/admin/store/store-wallet/${id}`} className="btn btn-sm btn-danger store_add_wallet_add">
                            <FaWallet />&nbsp;
                            Payout
                          </Link> &nbsp;
                          <Link
                            className="btn btn-sm btn-danger store_add_wallet_add" onClick={() => handleGetCummission(singleStoreData)}>
                            <PiPercentFill />&nbsp; Commission
                          </Link> &nbsp;
                          <Link to="/admin/orders"
                            className="btn btn-sm btn-danger store_add_wallet_add" >
                            <i class="fa fa-cart-plus"></i>&nbsp; Orders
                          </Link> &nbsp;
                        </div>

                        <div className='col-md-3'>

                        </div>

                      </div>
                    </>
                  </div>
                  <div>

                  </div>
                </div>
              </div>
            </>
          )}


      </div>


      <Modal show={show} onHide={handleClose}>

        <Modal.Header closeButton className='modal-header-commission'>
          <Modal.Title>Commission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <span className='td_check_all_for mb-3 mt-2'>
                <span>
                  <img src={storeCommissionUpdate?.store_logo ? fileUpload + storeCommissionUpdate?.store_logo : Storelogo} alt='store' className='store_image_check' />
                </span>
                <span className='support_store_check_fisrt'>
                  <strong>{storeCommissionUpdate?.store_name}</strong><br />

                  <CiLocationOn />{storeCommissionUpdate?.store_address}
                </span><br />
              </span>

              <p className='paragrapth_for_store mb-3'>You can update the commission for the store from here.</p>

              <div className="col-12 col-sm-12 mt-3">
                <div className="form-group local-forms">
                  <label>
                    Commission Percentage
                    <span className="login-danger"></span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name='Commission'
                    value={storeCommissionUpdate.store_commission_percentage}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer-store-view'>
          <Button variant="secondary" onClick={updateStoreCommission}>
            Save
          </Button>
        </Modal.Footer>

      </Modal>
    </>
  )
}

export default StoreSingleView;
